<template>
    <mds-notification-container>
        <mds-notification
            :variation="notificationType"
            :key="keyType"
            :size="size"
            :title="notificationTitle"
            :dismissDelay="parseInt(dismissDelay)"
            @mds-notification-dismissed="$emit('close')"
            tinted>
        {{ notificationMessage }}
        </mds-notification>
    </mds-notification-container>
</template>
<script>
import { MdsNotification, MdsNotificationContainer } from '@mds/notification';

export default {
    components: {
        MdsNotification,
        MdsNotificationContainer
    },
    props:{
        notificationMessage: {
            type: String,
            required: true
        },
        notificationType: {
            type: String,
            required: true
        },
        keyType: {
            type: String,
            default: 'informational-example'
        },
        size: {
            type: String,
            default: 'small'
        },
        dismissDelay: {
            type: Number,
            default: 3000
        },
        notificationTitle: {
            type: String,
            default: ""
        }
    }
    
}
</script>