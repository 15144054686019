<template>
<div>
<!-- <mds-dialog v-model="toggle" action-required :width="dialogSize">
     <mds-loader :size="loaderSize"></mds-loader>
</mds-dialog> -->
<div class="dialog-wrapper">
 <div class="dialog">
            <mds-loader size="large"></mds-loader>
        </div>
        <div class="overlay" style="    display: block;
    opacity: 0.5;">
       

    </div>
</div>
    
</div>
    <!-- <div class="loading-overlay loaderCursor">
        <mds-loader :size="loaderSize"></mds-loader>
    </div> -->
</template>
<script>
import MdsLoader from '@mds/loader'
// import MdsDialog from '@mds/dialog'
export default {
components:{
        MdsLoader,
        // MdsDialog
    },
    props: {
        loaderSize:{
            type:String
        }
    },
    data(){
        return{
            toggle:true,
            dialogSize:'350px'
        }
    },
    mounted(){
        if(this.loaderSize == "large"){
            this.dialogSize = "500px"
        }else{
            this.dialogSize="350px"
        }
    }
    
}
</script>
<style lang="scss" >
 .loading-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        
        // background-color: hsla(0, 0%, 80%, 0.5);
        z-index:100;
    }
    .overlay{
        background: #000000;
        bottom: 0;
        display: none;
        left: 0;
        opacity: 0;
        position: fixed;
        right: 0;
        top: 0;
        transition: opacity 0.5s ease-out;
        visibility: visible;
        z-index: -1;
    }
    .dialog{
            background-color: #ffffff;
            border: solid 1px #cccccc;
            border-radius: 6px;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
            max-width: 350px;
            padding: 16px;
            visibility: visible;
            width: 100%;
            z-index: 1010;
    }
    .dialog-wrapper{
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        left: 0;
        position: fixed;
        top: 0;
        visibility: hidden;
        width: 100%;
        z-index: 1010;
    }
    
</style>